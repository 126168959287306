import styled from "styled-components";

export const ChapterNav = styled.nav`
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  & a {
    font-size: 75%;
    background-color: var(--linkColor);
    font-family: var(--sansSerifFont);
    color: var(--backgroundColor) !important;
    padding: 0 10px;
    border-radius: 16px;
    opacity: 0.5;
    transition: 0.5s;
    &:hover {
      opacity: 1;
      text-decoration: none;
      box-shadow: 0 0 5px var(--linkColor);
    }
  }
`;

export const TextSection = styled.div`
  max-width: 800px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  & h1 {
    text-align: center;
    margin: var(--lineHeight) 0;
  }
  h2 {
    text-align: center;
    font-style: italic;
    font-weight: normal;
    margin-top: 0;
    padding-bottom: var(--lineHeight);
    margin-bottom: var(--lineHeight);
  }
  & p {
    margin: 0;
    & + p {
      text-indent: var(--lineHeight);
    }
  }
  & + nav {
    margin-top: var(--lineHeight);
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }
`;
