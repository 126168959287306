import React from "react";
import Layout from "../components/layout";
import { TextSection } from "./../styledcomponents/ChapterStyles";

const AboutPage = () => (
  <Layout>
    <TextSection>
      <h1>About this site</h1>
      <p>It is about detectives, and they are cats.</p>
    </TextSection>
  </Layout>
);

export default AboutPage;
